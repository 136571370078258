import router from '@/router';

export const config = {
	isURLHandlingEnabled: true,
	currency: '&euro;',
	isOnlyOnlineBuyout: false,
	language: 'sk',
	international_phone_prefix: '+421',
	router: router,
	swap_first_country: true,
	default_bank_code: '0200',
	privacy_policy: {
		text:
			'Súhlasím s pravidlami programu „{X}“ a so spracovaním osobných údajov potrebných pre účasť v programe.',
		link_title: 'Vymeň svoje staré zariadenie za nové online',
		link: 'https://www.istores.sk/podmienky-online-vykupu'
	},
	stores_link: 'https://www.istores.sk/predajne',
	minimal_buyout_price: 0.5,
};
