<template>
	<div>
    <app-header />
		<div id="mainClaim" class="mainClaim green__wrapper fullBgColor bgGreen">
			<text-block class="h1" identifier="mainClaim"/>
			<banner identifier="mainClaim"/>
		</div>
		<div id="mainClaim__under">
			<text-block class="" identifier="mainClaim__under"/>
		</div>
		<buyout :resetButtonEnabled="false" :isBankAccount="false" :deviceFormsOffset="-100">
			<template v-slot:pre__deviceForms="{ isBuyoutOnline }">
				<template v-if="isBuyoutOnline">
					<div class="buyout__help">{{ $t('buyout.need_visit_shop') }} -
						<span
							v-scroll-to="{
                            el: '#howItWorks',
                            offset: -50,
                            duration: 500
                        }"
						>{{ $t('buyout.need_visit_shop_tutorial') }}</span
						>
					</div>
				</template>
			</template>
		</buyout>
		<div id="bonusOffer" class="bonusOffer">
			<text-block identifier="bonusOffer__title"/>
			<text-block identifier="bonusOffer__text"/>
			<banner identifier="bonusOffer__banner"/>
			<bonus-devices/>
		</div>
		<div id="secondClaim" class="secondClaim">
			<text-block class="green__wrapper" identifier="secondClaim"/>
			<banner class="banner" identifier="secondClaim"/>
		</div>
		<how-it-works id="howItWorks"/>
		<div id="info" class="green__wrapper info__wrapper">
			<text-block identifier="info"/>
		</div>
		<div id="examples" class="examples">
			<text-block identifier="examples"/>
			<text-block identifier="examples__table"/>
		</div>
		<div id="info2">
			<text-block identifier="info2" class="green__wrapper info__wrapper"/>
		</div>
		<div id="info3">
			<text-block identifier="info3"/>
			<banner class="banner" identifier="info3"/>
			<text-block identifier="info3_2"/>
		</div>
		<div id="faq" class="faq">
			<text-block identifier="faq"/>
			<faq/>
			<text-block identifier="faq_2" class="faq__footer"/>
		</div>
		<app-footer />
	</div>
</template>

<script>
	import {buyout} from 'js-mp';
	import BonusDevices from '../components/BonusDevices/App';
  import AppHeader from "../components/AppHeader/App.vue";
  import AppFooter from "../components/AppFooter/App.vue";

	export default {
		name: 'HomeView',
		mounted() {
			if (buyout.config.scrollToElements && this.$route.params.category) {
				this.$scrollTo('#deviceFinder', {offset: -150, duration: 500});
			}
		},
		components: {AppFooter, AppHeader, ...buyout.components, BonusDevices}
	};
</script>
