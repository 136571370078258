import CookieBar from "@olc/cookie-bar";
import "@olc/cookie-bar/dist/udm/cookie-bar.css";

// create cookie bar with custom config
const cookieBar = new CookieBar({
	reloadOnUpdate: false,
	expires: 365 * 10,
	colors: {
		primary: '#0d8131'
	}
});

cookieBar.addOption(
	'necessarily',
	{
		sk: {
			title: 'Nutné',
			subTitle: '',
			description:
				'Technické cookies sú nutné pre správne fungovanie tejto stránky a neuchovávajú ' +
				'o užívateľovi identifikovateľné údaje. Tieto cookies nejde zakázať.'
		}
	},
	true,
	true
);

cookieBar.addOption(
	'advertising',
	{
		sk: {
			title: 'Marketing a ďalšie',
			subTitle: '',
			description:
				'Tieto cookies nám pomáhajú zobraziť personalizovanú ponuku na reklamných plochách na ' +
				'iných webových stránkach a analyzovať súhrnné dáta.'
		}
	},
	false,
	true
);

cookieBar.setLanguage('sk', {
	bar_mainHeader: 'Súbory cookies',
	bar_description:
		'Naše webové stránky používajú súbory cookies. Niektoré z nich sú pre ' +
		'správny chod webu nutné. Ďalšie z nich vylepšujú Váš užívateľský zážitok a ' +
		'pomáhajú nám náš web rozvíjať. Váš výber budete môcť kedykoľvek zmeniť.',
	button_continue: 'Povoliť všetko'
});

export function loadGTM(gtmId) {
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		window.dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', gtmId);
		// Improve ad click measurement quality (optional)
	gtag('set', 'url_passthrough', true);
	// Further redact your ads data (optional)
	gtag('set', 'ads_data_redaction', true);

	((w, d, s, l) => {
		w[l] = w[l] || [];
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js'
		});
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s);
		j.async = true;
		j.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer');
}

cookieBar.on('completion', cb => {
	const marketingConsent = cb.getOption('advertising').enable
		? 'granted'
		: 'declined';
	window.dataLayer.push('consent', 'default', {
		personalization_storage: marketingConsent
	});

	function fbq() {
		window.fbq.callMethod
			? window.fbq.callMethod.apply(window.fbq, arguments)
			: window.fbq.queue.push(arguments);
	}
	if (marketingConsent === 'granted') {
		// Global site tag (gtag.js) - Google Ads: 762100629 - iStores
		loadGTM('AW-985425585');

		// facebook
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod
					? n.callMethod.apply(n, arguments)
					: n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(
			window,
			document,
			'script',
			'https://connect.facebook.net/en_US/fbevents.js'
		);
		fbq('init', '1766092216856694'); // Insert your pixel ID here.
		fbq('track', 'PageView');

		const noScriptFBQ = document.createElement('noscript');
		noScriptFBQ.innerHTML = `<noscript>
											<img
												height="1"
												width="1"
												style="display: none;"
												src="https://www.facebook.com/tr?id=1766092216856694&ev=PageView&noscript=1"
												alt="..."	
											/>
										</noscript>`;
		document.body.prepend(noScriptFBQ);

		//INRES REMARKETING START v2std
		((w, d, s) => {
			if (typeof g_ntscRmkQueue == 'undefined') var g_ntscRmkQueue = [];
			g_ntscRmkQueue.push({
				t: 16 /* tag id, do not change */,
				e: null /* optional custom event(s), string or array of strings */,
				p: null /* optional product id(s) for dynamic remarketing, string or array of strings */
			});
			const f = d.getElementsByTagName(s)[0];
			const j = d.createElement(s);
			j.async = true;
			j.src = `https://inres.uspech.sk/js/rsysc.js`;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script');
	}
});

window.cookieBar = cookieBar;

// render cookie bar
cookieBar.render();
