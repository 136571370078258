export const overrides = {
	sk: {
		buyout: {
			need_visit_shop: 'Pre uskutočnenie výkupu online je potrebné mať vytvorenú objednávku na nové zariadenia v našom eshope',
			need_visit_shop_tutorial: 'kompletný návod tu',
			contractForm: {
				note: 'Číslo objednávky nového zariadenia'
			},
			thankYou: {
				onlineBuyout: {
					title: 'Informatívne nacenenie zariadenia bolo odoslané na Vašu e-mailovú adresu pod číslom <b>{0}</b>.',
					text: 'Náš technik Vás bude kontaktovať v najkratšej možnej dobe pre dohodnutie vyzdvihnutia starého zariadenia.'
				}
			},
		}
	}
};